<template>
  <div class="container-fluid">
    <h3 class="text-light mt-3">Usuarios</h3>
    <div class="row justify-content-center">
      <div v-if="!btn" class="col-12">
        <UserTable :usuarios="lstUsuarios" />
      </div>
      <div v-if="btn" class="col-12"><CreateUserForm  /></div>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import CreateUserForm from "../components/CreateUserForm.vue";
import UserTable from "../components/UserTables.vue";
import useUsuarios from "../composable/useUsuarios";
import { useStore } from "vuex";
import { inject, onMounted } from "vue-demi";
export default {
  components: {
    CreateUserForm,
    UserTable,
  },
  setup() {
    const store = useStore();
    const btn = inject("btn");
    const { getUser, lstUsuarios } = useUsuarios();
    const boxUser = reactive({
      activo: 0,
      inactivo: 0,
      total: 0,
    });

    onMounted(async () => {
      store.commit("showLoader");
      await getUser();
      store.commit("hideLoader");
    });
    
    return { lstUsuarios, btn, boxUser };
  },
};
</script>

<style>
</style>