<template>
  <div class="text-end mt-2 mb-2">
    <button class="myButtonAceptar" @click="agregarUser">Agregar</button>
  </div>
  <div class="card table-responsive-sm">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Nombre</th>
          <th scope="col">Email</th>
          <th scope="col">Rol</th>
          <th scope="col">Eliminar</th>
        </tr>
      </thead>
      <tbody v-for="u of usuarios" v-bind:key="u.id">
        <tr style="cursor: pointer">
          <td @click="select(u.user)">{{ u.user.name }}</td>
          <td @click="select(u.user)">{{ u.user.email }}</td>
          <td @click="select(u.user)">{{ u.role.nombre }}</td>
          <td>
            <button class="btnEliminarTable" @click="eliminarUser(u.user)">
              <i style="color: #fff" class="far fa-trash-alt"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { inject } from "vue-demi";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import useUsuarios from "../composable/useUsuarios";
export default {
  props: ["usuarios"],

  setup() {
    const objUser = inject("objUser");
    const imageUrl = inject("imageUrl");
    const btn = inject("btn");
    const store = useStore();
    const { editUser,getUser } = useUsuarios();

    const select = (u) => {
      objUser.name = u.name;
      objUser.email = u.email;
      objUser.telefono = u.telefono;
      objUser.role_id = u.role_id;
      objUser.id = u.id;
      objUser.status = u.status;
      objUser.deleted = u.deleted;
      objUser.password = "123456";
      objUser.password_confirmation = "123456";
      imageUrl.value = u.foto ? process.env.VUE_APP_URL_IMG + u.foto : null;
      btn.value = true;
    };

    const agregarUser = () => {
      objUser.name = "";
      objUser.email = "";
      objUser.telefono = "";
      objUser.role_id = "";
      objUser.id = null;
      objUser.status = null;
      objUser.password = "";
      objUser.password_confirmation = "";
      imageUrl.value = "";
      btn.value = true;
    };

    const eliminarUser = (u) => {
      if (u.id == 1) {
        return Swal.fire(
          "Excelente!",
          "Este usuario no puede ser eliminado",
          "info"
        );
      }
      Swal.fire({
        title: "¿Seguro que desea eliminar este registro?",
        showCancelButton: true,
        confirmButtonText: "Si",
      }).then(async (result) => {
        if (result.isConfirmed) {
          objUser.name = u.name;
          objUser.email = u.email;
          objUser.telefono = u.telefono;
          objUser.role_id = u.role_id;
          objUser.id = u.id;
          objUser.status = u.status;
          objUser.password = "123456";
          objUser.password_confirmation = "123456";
          imageUrl.value = u.foto ? process.env.VUE_APP_URL_IMG + u.foto : null;
          objUser.deleted = true;
          
          store.commit("showLoader");
          let resp = await editUser(objUser);
          await getUser();

          store.commit("hideLoader");
          if (resp.code != 200) {
            Swal.fire("Error!", resp.message, "error");            
          } 
             
        }
      });
    };

    return { select, agregarUser, eliminarUser };
  },
};
</script>

<style>
</style>